import { useState, useEffect } from "react"

function useStripe() {
  const [stripe, setStripe] = useState(null)

  useEffect(() => {
    if (window.Stripe && !stripe) {
      setStripe(window.Stripe(process.env.STRIPE_API_KEY))
    }
  })

  return stripe
}

export default useStripe
